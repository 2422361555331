import { useContext, useEffect } from 'react';

import { RoutesContext } from '@confluence/route-manager';

import { appendGoogleMeetScreenSharingScript } from './appendGoogleMeetScreenSharingScript';

// Adds Google Meet addon script and loads methods to allow for Confluence add-on promotion during screensharing
export const useExposeGoogleMeetScreenShare = (): void => {
	// Linter is forcing us to use getHref instead of window.location.href to retrieve href and it isn't the full href - it doesn't include origin
	const { getHref } = useContext(RoutesContext);

	useEffect(() => {
		// https://atlassian.slack.com/archives/C0131PNA4GL/p1723738281832269
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR && 'setCaptureHandleConfig' in (navigator?.mediaDevices ?? {})) {
			const href = getHref();
			appendGoogleMeetScreenSharingScript(href);
		}
		// Only execute on initial render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
