declare global {
	interface Window {
		__preloaderPromiseForSuspense__?: Map<string, SuspenseableDataFetcher>;
	}
}

export type SuspenseableDataFetcher = {
	read: () => { data: any; error: any } | undefined;
};

export function getDataResourceForSuspense(preloaderName: string): SuspenseableDataFetcher | null {
	return window['__preloaderPromiseForSuspense__']?.get(preloaderName) ?? null;
}

export function createWrappedPreloader({
	promise,
	preloaderName,
}: {
	promise: Promise<any> | Promise<any>[];
	preloaderName: string;
}) {
	const urlParams = new URLSearchParams(window.location.search);
	const mockDelay = urlParams.get('MOCK_SUSPENSE_QUERYDELAY');
	const delay = mockDelay ? parseInt(mockDelay) : 0;

	if (!window['__preloaderPromiseForSuspense__']) {
		window['__preloaderPromiseForSuspense__'] = new Map<string, SuspenseableDataFetcher>();
	}

	const existingPromise = window['__preloaderPromiseForSuspense__'].get(preloaderName);

	if (existingPromise) {
		return existingPromise;
	} else {
		const wrappedPromise = preloaderWrapperForSuspense({
			promise,
			delay,
		});

		window['__preloaderPromiseForSuspense__'].set(preloaderName, wrappedPromise);
		return wrappedPromise;
	}
}

export function preloaderWrapperForSuspense({
	promise,
	delay,
}: {
	promise: Promise<any> | Promise<any>[];
	delay: number;
}): SuspenseableDataFetcher {
	let dataResult: any = null;
	let errorResult: any = null;
	let promiseState: 'pending' | 'resolved' | 'error' = 'pending';

	if (Array.isArray(promise)) {
		promise = Promise.all(promise);
	}

	promise
		.then((result) => {
			if (delay > 0) {
				setTimeout(() => {
					dataResult = result;
					promiseState = 'resolved';
				}, delay);
			} else {
				dataResult = result;
				promiseState = 'resolved';
			}
		})
		.catch((error) => {
			errorResult = error;
			promiseState = 'error';
		});

	return {
		read() {
			if (promiseState === 'pending') {
				throw promise;
			} else if (promiseState === 'error') {
				return { data: null, error: errorResult };
			} else if (promiseState === 'resolved') {
				return {
					data: dataResult,
					error: errorResult,
				};
			}
		},
	};
}
